import React, { useState, useEffect } from 'react';
import bg from './bg.jpg';
import './App.css';

function App() {

  const [ sended, setSended ] = useState(false)

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

  const sendEmail = e => {
    e.preventDefault()
    const email = { email: document.getElementById('email').value };

    fetch('https://api.webcreatio.net/avisame.php', {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify(email),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(res => {
      if (res) {
        setSended(true);
        setCookie('airsoftArgentina__emailRequested', true, 360)
      }
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    const cookie = getCookie('airsoftArgentina__emailRequested')
    if (cookie) {
      setSended(true)
    }
  }, [])

  /* function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  } */

  return (
    <div id="app">
      <img src={bg} alt="background" />
      <span className="content">
        <h1>Airsoft Argentina</h1>
        <h3>Proximamente</h3>
        <div className="formContainer">
          {
            sended ? (
              <div className="sended">Recibiras un email cuando estemos listos !</div>
            ) : (
              <form onSubmit={sendEmail}>
                Te avisamos cuando estemos en linea ?
                <span className="inputGroup">
                  <input type="email" name="email" id="email" placeholder="Ingresá tu email" required/>
                  <button type="submit">Avisame!</button>
                </span>
              </form>
            )
          }
        </div>
      </span>
    </div>
  );
}

export default App;
